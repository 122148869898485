<template>
  <div class="login__wrap">
    <div class="login__head">
      <el-image class="logoImg" :src="require('@/assets/img/logo.png')"></el-image>
    </div>
    <div class="login__bg">
      <div class="login__main">
        <div class="login__box">
          <div class="login-title">
            <!-- <el-image style="width:49px;height:45px;" :src="require('@/assets/img/logo-icon.jpeg')"></el-image> -->
            供销壹号配送管理后台
          </div>
          <el-form
            class="sign__main"
            ref="elForm"
            :model="form.data"
            :rules="form.rules"
            @submit.prevent="submit"
          >
            <el-form-item prop="account">
              <el-input v-model.trim="form.data.account" size="medium" maxlength="11" clearable placeholder="请输入账号" prefix-icon="el-icon-user"></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input v-model.trim="form.data.password" size="medium" clearable show-password placeholder="请输入密码" prefix-icon="el-icon-key"></el-input>
            </el-form-item>
            <el-form-item style="margin-top: 50px;">
              <el-button class="theneBtn" style="width: 100%;padding: 10px 40px;" type="primary" :loading="form.loading" :disabled="form.loading" @click.stop="submit">登录</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="foot">-江西金手指科技有限公司技术支持-</div>
    </div>
  </div>
</template>
<script>
import {setStorage} from '@/storage/index.js'
export default {
  name: 'Login',
  data() {
    return {
      curTrim: '',
      checked: false,
      form: {
        data: {
          account: '', // 18979158401, 18807093061(赣农优品ID:5)
          password: '', // 123456
        },
        rules: {
          account: [{ required: true, message: '请输入用户名', trigger:'blur' }],
          password: [{ required: true, message: '请输入正确密码', trigger:'blur' }],
        }
      },
    }
  },
  mounted() {
    this.curTrim = this.$moment().format('a') == 'am' ? '上午' : '下午'
  },
  methods: {
     submit() {
      this.$refs.elForm.validate((valid) => {
        if (valid) {
          let _params = {
            account: this.form.data.account,
            password: this.form.data.password
          };
          console.log(_params)
          // return
          this.$http.post('/admin/admin/login', _params).then(res => {
            if(res.code == 1) {
              setStorage('token', res.data.token)
              setStorage('userName', res.data.name)
              setStorage('userId', res.data.subject_id)
              setStorage('account', res.data.account)
              setStorage('is_sys', res.data.is_sys); //是否是系统创建 1:是（超级管理员） 0:否
              setStorage('role_type', res.data.role_type) //色类型：1-超级管理员；2-管理员；3-员工；4-财务
              setStorage('province', res.data.province)
              setStorage('city', res.data.city)
              setStorage('subject_name', res.data.subject_name)
              setStorage('open_pick', res.data.open_pick); // 是否开启分拣功能：1-开启；2-关闭
              setStorage('is_showDialog', true); // 登录时默认消息弹窗
              setStorage('access_list', res.data.access_list); // 该账号的权限菜单列表
              this.$notify({
                title: '欢迎',
                message: `${this.curTrim}好，欢迎回来！`,
                type: 'success'
              });
              this.$router.push('/'+res.data.access_list[0].web_path)
              // this.$router.push('/home/index')
            }
          })
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.login__wrap {
  width: 100%;
  height: 100vh;

  .login__head {
    height: 65px;
    background: #fff;
    display: flex;
    align-items: center;
    padding: 0 60px;
  }

  .login__bg {
    height: calc(100vh - 65px);
    background-image: url('~@/assets/img/bg.png');
    background-size:cover;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
  }

  .logoImg {
    width: 210px;
    height: 64px;
  }
  
  .login__main {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-right: 12%;

    .login-title {
      font-size: 22px;
      font-weight: bold;
      text-align: center;
      color: #303133;
      margin-top: 0;
      margin-bottom: 40px;
      // display: flex;
      align-items: center;
    }

    .login__box {
      width: 420px;
      padding: 50px;
      border-radius: 10px;
      background-color: #fffefe;
      box-sizing: border-box;
      overflow: hidden;
    }
    .theneBtn {
      background-color: $theme !important;
      border-color: $theme !important;
      font-size: 16px;
      font-weight: bold;
    }
  }

  .foot {
    margin-bottom: 40px;
    color: #326B2C;
    text-align: center;
    width: 100%;
  }
}
</style>
